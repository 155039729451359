import { PUBLISHER_STATUS } from '@/model/modules/ecommerce/publisher/PublisherStatus';

export default Object.freeze({
  noRestrictionsToggle: {
    value: true,
    text: 'No restrictions',
    message: 'Let all the traffic from this source enter the offers. No controls will be applied.',
  },
  status: PUBLISHER_STATUS,
});
