<template>
  <sun-form @submit="submitForm" @keypress.enter="submitForm">
    <card-form>
      <template slot="title">Subpublisher Data</template>
      <template slot="form">
        <sun-file-uploader accept=".csv,text/csv" :max-size="maxCsvSize" required="required" @change="setFile" />
      </template>
    </card-form>

    <div class="mt-12">
      <save-button :disabled="!file" :loading="loading" text="Upload" />
    </div>
  </sun-form>
</template>
<script>
import { mapActions } from 'vuex';
import CardForm from '@/components/atoms/CardForm';
import { uploadAdditionalData } from '@/services/modules/ecommerce/subpublisher';
import SaveButton from '@/components/atoms/SaveButton';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';

export default {
  name: 'AdditionalInfo',
  components: { CardForm, SaveButton },
  props: {
    basePublisher: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    file: null,
    maxCsvSize: 104857600,
  }),
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    setFile(files) {
      this.file = files[0];
    },
    async submitForm() {
      try {
        this.loading = true;
        const { error, message } = await uploadAdditionalData(this.basePublisher.id, this.file);

        if (error) {
          this.createToast(Toast.error(`Can't upload the file`, error));
        } else if (message) {
          this.createToast(Toast.success('File uploaded successfully', message));
        }
      } catch (error) {
        this.createToast(Toast.error(`Can't upload the file`, error.message));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
