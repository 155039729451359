var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col w-full" },
    [
      _vm._t("title", function () {
        return [_vm._v("Title")]
      }),
      _c("div", { staticClass: "flex w-full items-center justify-end mb-2" }, [
        _c(
          "div",
          {
            staticClass: "p-1 border rounded-xl rounded-r-none cursor-pointer",
            class: [_vm.isIncluded ? "bg-orange-600 text-white" : ""],
            on: {
              click: function ($event) {
                return _vm.optionTypeChange(_vm.selectorOptions.INCLUDE)
              },
            },
          },
          [_vm._v(" Include ")]
        ),
        _c(
          "div",
          {
            staticClass:
              "p-1 border rounded-xl rounded-l-none border-l-0 cursor-pointer",
            class: [!_vm.isIncluded ? "bg-orange-600 text-white" : ""],
            on: {
              click: function ($event) {
                return _vm.optionTypeChange(_vm.selectorOptions.EXCLUDE)
              },
            },
          },
          [_vm._v(" Exclude ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "w-full flex flex-wrap" },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "div",
            {
              key: `option__${index}`,
              staticClass:
                "flex items-center py-1 px-2 m-1 border rounded-full cursor-pointer bg-gray-100",
              class: [
                _vm.optionsIncluded.includes(option.id) ? "bg-green-300" : "",
                _vm.optionsExcluded.includes(option.id) ? "bg-red-300" : "",
              ],
              on: {
                click: function ($event) {
                  return _vm.onOptionClick(option.id)
                },
              },
            },
            [_vm._v(" " + _vm._s(option.name) + " ")]
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }