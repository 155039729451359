<template>
  <div class="flex flex-col w-full">
    <slot name="title">Title</slot>
    <div class="flex w-full items-center justify-end mb-2">
      <div
        class="p-1 border rounded-xl rounded-r-none cursor-pointer"
        :class="[isIncluded ? 'bg-orange-600 text-white' : '']"
        @click="optionTypeChange(selectorOptions.INCLUDE)"
      >
        Include
      </div>
      <div
        class="p-1 border rounded-xl rounded-l-none border-l-0 cursor-pointer"
        :class="[!isIncluded ? 'bg-orange-600 text-white' : '']"
        @click="optionTypeChange(selectorOptions.EXCLUDE)"
      >
        Exclude
      </div>
    </div>
    <div class="w-full flex flex-wrap">
      <div
        v-for="(option, index) in options"
        :key="`option__${index}`"
        class="flex items-center py-1 px-2 m-1 border rounded-full cursor-pointer bg-gray-100"
        :class="[
          optionsIncluded.includes(option.id) ? 'bg-green-300' : '',
          optionsExcluded.includes(option.id) ? 'bg-red-300' : '',
        ]"
        @click="onOptionClick(option.id)"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>
<script>
const selectorOptions = {
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
};

export default {
  name: 'OptionsSelector',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    defaultSelection: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      optionsIncluded: [],
      optionsExcluded: [],
      selectorOptions,
      typeSelector: null,
    };
  },
  computed: {
    isIncluded() {
      return this.typeSelector === selectorOptions.INCLUDE;
    },
  },
  mounted() {
    this.typeSelector = this.defaultSelection?.type || selectorOptions.INCLUDE;
    if (this.isIncluded) {
      this.optionsIncluded = this.defaultSelection?.ids || [];
    } else {
      this.optionsExcluded = this.defaultSelection?.ids || [];
    }
    if (!this.defaultSelection) {
      this.emitChange();
    }
  },
  methods: {
    onOptionClick(optionId) {
      if (this.isIncluded) {
        const optionIndex = this.optionsIncluded.indexOf(optionId);
        if (optionIndex > -1) {
          this.optionsIncluded.splice(optionIndex, 1);
        } else {
          this.optionsIncluded.push(optionId);
        }
      } else {
        const optionIndex = this.optionsExcluded.indexOf(optionId);
        if (optionIndex > -1) {
          this.optionsExcluded.splice(optionIndex, 1);
        } else {
          this.optionsExcluded.push(optionId);
        }
      }
      this.emitChange();
    },
    optionTypeChange(optionType) {
      this.typeSelector = optionType;
      this.optionsIncluded = [];
      this.optionsExcluded = [];
      this.emitChange();
    },
    emitChange() {
      this.$emit('change', {
        type: this.typeSelector,
        value: this.isIncluded ? this.optionsIncluded : this.optionsExcluded,
      });
    },
  },
};
</script>
