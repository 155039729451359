<template>
  <div>
    <div v-if="isLoading || loadingPublisher">
      <card-form-loading :rows="3" class="mt-10"></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
    </div>
    <sun-form v-else @submit="submitForm" @keypress.enter="submitForm">
      <card-form>
        <template slot="title"> Basic Info </template>
        <template slot="form">
          <form-row>
            <template #left>
              <sun-label-group text="Name">
                <asterix-input
                  v-model="publisher.name"
                  type="text"
                  name="name"
                  :minlength="3"
                  :maxlength="50"
                  auto-validate
                  validate-on-blur
                  placeholder="Publisher Name"
                  required="required"
                />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="Status">
                <sun-select
                  v-model="publisher.status"
                  class="cursor-pointer"
                  label="name"
                  name="status"
                  :options="status.options"
                  placeholder="Active"
                  track-by="value"
                  text-error="This field is required"
                  close-on-select
                  required="required"
                />
              </sun-label-group>
            </template>
          </form-row>
          <category-selector v-model="publisher" />
          <form-row>
            <template #left>
              <sun-label-group text="Our Commission (%)">
                <asterix-input
                  v-model="publisher.commissionValue"
                  type="number"
                  name="commissionValue"
                  value="0"
                  placeholder="3"
                  required="required"
                  :min="1"
                  :max="100"
                  auto-validate
                />
              </sun-label-group>
            </template>
            <template #right>
              <sun-label-group text="TAX ID">
                <asterix-input
                  v-model="publisher.taxId"
                  type="text"
                  name="taxId"
                  placeholder="TAX ID"
                  :maxlength="15"
                  auto-validate
                />
              </sun-label-group>
            </template>
          </form-row>
          <form-row>
            <template #left>
              <sun-label-group text="Navision ID">
                <asterix-input
                  v-model="publisher.navisionId"
                  type="text"
                  name="navisionId"
                  validate-on-blur
                  placeholder="Navision ID"
                  :maxlength="30"
                  auto-validate
                />
              </sun-label-group>
            </template>
          </form-row>
          <form-row>
            <template #left>
              <div class="flex w-1/3 mr-4">
                <span class="text-sm font-bold text-gray-700 whitespace-no-wrap">No Restrictions</span>
                <sun-toggle v-model="publisher.noRestrictions" class="ml-5" />
              </div>
              <span v-show="publisher.noRestrictions" class="text-sm text-gray-600 font-bold">
                {{ noRestrictions.message }}
              </span>
            </template>
            <template #right>
              <options-selector :options="clients" :default-selection="publisher.clientIds" @change="onClientsChange">
                <template #title>
                  <p class="font-bold">Clients</p>
                </template>
              </options-selector>
            </template>
          </form-row>
        </template>
      </card-form>

      <card-form>
        <template slot="title">Postback</template>
        <template slot="form">
          <form-row>
            <template #right>
              <div class="w-full flex justify-end">
                <sun-button
                  variant="pill"
                  class="text-sm custom-p-1"
                  :loading="loadingRestTracking"
                  @click="resetTrackingLink"
                >
                  Reset Tracking Links
                </sun-button>
              </div>
            </template>
          </form-row>

          <url-creator
            v-model="publisher.postBackUrl"
            label="Postback Url"
            class="w-full"
            :options="urlCreatorOptions"
            show-details
          >
            <template #info-popover-content>
              <macro-info :macro-descriptions="macroDescriptions" />
            </template>
          </url-creator>
        </template>
      </card-form>

      <div class="mt-12">
        <save-button :loading="isLoading" />
      </div>
    </sun-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import CardForm from '@/components/atoms/CardForm';
import SaveButton from '@/components/atoms/SaveButton';
import updatePublisher from '@/services/modules/ecommerce/publisher/updatePublisher';
import createPublisher from '@/services/modules/ecommerce/publisher/createPublisher';
import FormRow from '@/components/atoms/FormRow/FormRow';
import CONFIG from './config';
import CONFIG_MACROS from '@/views/private/modules/ecommerce/publisher/publisherMacros';
import UrlCreator from '@/components/molecules/shared/UrlCreator/UrlCreator';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { list as listPublisherRoute } from '@/router/private/modules/ecommerce/supply/publisher/list';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import AsterixInput from '@/components/atoms/AsterixInput';
import CategorySelector from '@/components/molecules/shared/CategorySelector';
import { deepClone } from '@/utils/deepClone';
import resetExtraParamsTrackerLink from '@/services/modules/ecommerce/publisher/resetExtraParamsTrackerLink';
import MacroInfo from '@/components/molecules/modules/ecommerce/publisher/MacroInfo';
import OptionsSelector from '@/components/molecules/modules/ecommerce/publisher/OptionsSelector';
import Publisher from '@/entities/ecommerce/Publisher';

export default {
  name: 'PublisherBasicInfo',
  components: {
    FormRow,
    CardForm,
    SaveButton,
    UrlCreator,
    CardFormLoading,
    AsterixInput,
    CategorySelector,
    MacroInfo,
    OptionsSelector,
  },
  props: {
    basePublisher: {
      type: Object,
      default: null,
    },
    clients: {
      type: Array,
      default: () => [],
    },
    loadingPublisher: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    isLoading: false,
    hasError: false,
    noRestrictions: CONFIG.noRestrictionsToggle,
    categoryList: [],
    status: { options: CONFIG.status, selected: null },
    categories: { options: [], selected: null },
    subcategories: { options: [], selected: null },
    requiredText: 'This field is required',
    clientIds: null,
    publisher: new Publisher(),
    loadingRestTracking: false,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    urlCreatorOptions: () => CONFIG_MACROS.urlCreatorOptions,
    macroDescriptions: () => CONFIG_MACROS.macroDescriptions,
    isEdit() {
      return !!this.$route.params.publisherId;
    },
    mode() {
      return this.isEdit ? 'edit' : 'new';
    },
  },
  watch: {
    basePublisher(basePublisher) {
      this.loadPublisher(basePublisher);
    },
  },
  created() {
    if (this.basePublisher) this.loadPublisher(this.basePublisher);
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async submitForm({ valid }) {
      if (!valid) return;
      this.isLoading = true;
      const type = this.isEdit ? 'updated' : 'created';
      try {
        this.publisher.clientIds = this.clientIds;
        if (this.isEdit) {
          await updatePublisher(this.publisher);
        } else {
          await createPublisher(this.publisher);
        }
        this.createToast(
          Toast.success(`Publisher ${type}`, `Publisher ${this.publisher.name} was ${type} successfully.`)
        );
        await this.$router.push(listPublisherRoute);
      } catch (error) {
        this.createToast(Toast.error(`Publisher not ${type}`, error.message));
        this.hasError = true;
      } finally {
        this.isLoading = false;
      }
    },
    loadPublisher(basePublisher) {
      this.publisher = deepClone(basePublisher);
    },
    async resetTrackingLink() {
      this.loadingRestTracking = true;
      try {
        const response = await resetExtraParamsTrackerLink(this.publisher.id, this.activeClient.id);
        this.createToast(Toast.success(`Publisher Updated`, response.message));
      } catch (e) {
        this.createToast(Toast.error(`Error updating publisher`, e.message));
      }

      this.loadingRestTracking = false;
    },
    onClientsChange({ type, value }) {
      this.clientIds = { type, ids: value };
    },
  },
};
</script>
