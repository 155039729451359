var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sun-form",
    {
      on: {
        submit: _vm.submitForm,
        keypress: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "card-form",
        [
          _c("template", { slot: "title" }, [_vm._v("Subpublisher Data")]),
          _c(
            "template",
            { slot: "form" },
            [
              _c("sun-file-uploader", {
                attrs: {
                  accept: ".csv,text/csv",
                  "max-size": _vm.maxCsvSize,
                  required: "required",
                },
                on: { change: _vm.setFile },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "mt-12" },
        [
          _c("save-button", {
            attrs: {
              disabled: !_vm.file,
              loading: _vm.loading,
              text: "Upload",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }