import { api } from '.';

/**
 * Upload additional subpublisher data
 *
 * @param {string} publisherId
 * @param {File} file
 * @returns {Promise.<Object>}
 */
export async function uploadAdditionalData(publisherId, file) {
  const partialUrl = api.createUrl({
    subpublisherdata: publisherId,
    updatedata: undefined,
  });

  const payload = new FormData();
  payload.append('file', file);

  const { data } = await api.create(partialUrl, payload);
  return data;
}
